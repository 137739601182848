import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">社宅公共藝術咖啡桌 / 第二輪</h2>
          <h1 className="main_title">#傳承創新</h1>
        </div>


        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_7">
              <figure className="figure">
                <img src={require("../../images/blog/post_2_6_2.jpg")} alt=""/>
                <figcaption className="figcaption left">組長：林書豪<br/>組員：吳俊毅、陳姵潔、景雅琦</figcaption>
              </figure>
            </div>
          </section>
          <section className="row_area_s">
            <div className="column_5">
              <div className="context">
                <p>第二輪討論提及社宅公共藝術在公民社會幾個面向。</p>
                <p>第一，多元社區參與，如透過市集來串聯在地與外來的人們，讓在地居民跟外來民眾創造連結。</p>
                <p>第二，藉由社宅公共空間，提出使用者介面的實驗與推廣。例如：遊具的設計開發，由住戶的使用經驗，累積反饋資料，作為臺灣公民社會未來遊具開發的參考。 </p>
                <p>此外，社宅公共空間也可能是社區客廳，不只供社宅居民使用，也服務周邊居民。如托育、托老等設施。這些空間如何符合公共藝術方式呈現，是可以思考的地方。</p>
                <p>第三，社宅公共藝術可能是社區培力的育成基地。社宅的居民是流動的，但周邊社區是相對長久的，從另一面向思考，不一定是社宅提供地方資源，而是地方滋養社宅。舉例來說：地方有豐富的田野故事，而社宅公共藝術成為一個中介，讓藝術家轉譯地方文化內涵，產出戲劇作品。社宅也可以是臺北市的社區培力中心，當居民離開社區後，可能成為下一個社區培力他人的角色，持續擴大培力動能。</p>
                <p>其它組員補充，現在社區已經有社區大學和社區規劃師，但可能限於政策等因素，只著重於某些議題，也許透過藝術家的眼光觀察，可以找出隱藏性的議題，讓不同社會議題得以在公共藝術這個平台被傳達。</p>
                <p>另外，關於地方內容精緻化，舉新店的下城社區為例。當地高齡化嚴重，社區劇場老師帶領高齡者演出自己的故事，雖然他不是很會表達，但透過社區劇場，幫助社造者了解他們過去的生活經驗，未來其他的工作者也可以此為基礎，轉譯出精緻的戲劇作品。</p>
              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_2_7" className="next_page">
            <div className="next_title">#公民社會</div>
            <div className="next_owner">社宅公共藝術咖啡桌 / 第二輪</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
